/* eslint-disable no-multi-str */

export var negativa =
  "\
## What the Unchained Index is NOT:\n\
\n\
- Unchained Index is NOT censorable, and...\n\
\n\
  - therefore, CANNOT be stopped, and...\n\
\n\
  - CANNOT be captured.\n\
\n\
- Unchained Index is NOT expensive to run, and...\
\n\
  - ...therefore it is easily sustained.\n\
\n\
- Unchained Index CAN NOT snoop on queries to the database...\n\
\n\
  - ...therefore, it cannot invade users' privacy.\n\
\n\
- Unchained Index does NOT deliver unreproducable data...\n\
\n\
  - ...therefore, users can trust at their discretion.\n\
\n\
- Unchained Index is NOT finished, and...\n\
\n\
  - ...therefore, needs your support.\n\
\n\
";
