/* eslint-disable no-multi-str */
import React from 'react';

export const AboutBox = () => {
  return (
    <div>
      <h2>Team</h2>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 8fr 8fr',
          justifyContent: 'center',
        }}>
        <div></div>
        <div>Meriam Zandi</div>
        <div>Thomas Rush</div>
        <div></div>
        <div>
          <img style={{ border: '1px solid black' }} width='250px' src='images/Meriam.png' />
        </div>
        <div>
          <img style={{ border: '1px solid black' }} width='330px' src='images/Jay.png' />
        </div>
      </div>
    </div>
  );
};
