import React, { Component } from 'react';

import classNames from 'classnames';
import { isEmpty } from 'components';

import './Menu.css';

//-------------------------------------------------------
export const ToggleIcon = ({ on }) => {
  return (
    <div role='img' aria-label='Toggle' className='toggle-icon-symbol'>
      {on ? '-' : '+'}
    </div>
  );
};

//----------------------------------------------------------------------------
export const generateBranch = ({ node, nodeName, ...props }) => {
  const { nodes, label: rawLabel = 'unknown', ...nodeProps } = node;

  const key = [props.parent, nodeName].filter((x) => x).join('/');
  const hasNodes = !!nodes && !isEmpty(nodes);
  const isOpen = hasNodes && props.openNodes.includes(key);

  const label = rawLabel;

  const currentItem = { ...props, ...nodeProps, label, hasNodes, isOpen, key };
  if (!isOpen) return [currentItem];

  const data = !!nodes && !isEmpty(nodes) ? nodes : [];
  const children = generateChildren({ data, ...props, parent: key, level: props.level + 1 });
  return [currentItem, ...children];
};

//----------------------------------------------------------------------------
export const generateChildren = ({ data, ...props }) => {
  return data.reduce((all, node, index) => {
    const branchProps = {
      node,
      index,
      nodeName: node.key,
      parent: '',
      level: 0,
      ...props,
    };
    const branch = generateBranch(branchProps);
    return [...all, ...branch];
  }, []);
};

//-------------------------------------------------------
export const MenuItem = ({ hasNodes = false, isOpen = false, level = 0, onClick, toggleNode, active, label }) => {
  return (
    <li
      className={classNames('tree-item', `tree-item-level${level}`, { 'tree-item--active': active })}
      style={{
        paddingLeft: `${0.25 + (hasNodes ? 0 : 1) + level}rem`,
        margin: '0px',
        whiteSpace: 'nowrap',
        listStyleType: 'none',
      }}
      role='button'
      aria-pressed={active}
      onClick={onClick}>
      {hasNodes && (
        <div
          className='toggle-icon'
          onClick={(e) => {
            hasNodes && toggleNode && toggleNode();
            e.stopPropagation();
          }}>
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {label}
    </li>
  );
};

//---------------------------------------------------------
export class Menu extends Component {
  state = {
    openNodes: [],
    activeKey: this.props.defMenu && this.props.defMenu.key,
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.setState({ openNodes: [] });
    }
  }

  toggleNode = (node) => {
    const { openNodes } = this.state;
    const newOpenNodes = openNodes.includes(node)
      ? openNodes.filter((openNode) => openNode !== node)
      : [...openNodes, node];
    this.setState({ openNodes: newOpenNodes });
  };

  generateItems = () => {
    const { data, onClickItem } = this.props;
    const openNodes = this.state.openNodes;
    const activeKey = this.state.activeKey;

    const items = data ? generateChildren({ data, openNodes }) : [];

    return items.map((item) => {
      const active = item.key === activeKey;
      const onClick = () => {
        this.setState({ activeKey: item.key });
        onClickItem && onClickItem(item);
      };

      const toggleNode = item.hasNodes ? () => this.toggleNode(item.key) : undefined;
      return { ...item, active, onClick, toggleNode };
    });
  };

  //-------------------------------------------------------
  defaultChildren = ({ items }) => {
    return (
      <ul className='tree-item-group'>
        {items.map(({ key, ...props }) => (
          <MenuItem key={key} {...props}></MenuItem>
        ))}
      </ul>
    );
  };

  render() {
    const items = this.generateItems();
    return (this.props.children || this.defaultChildren)({ items });
  }
}

// Based heavily on https://github.com/iannbing/react-simple-tree-menu#readme
