import React from 'react';

import './Header.css';
import ThePig from 'assets/imgs/EthereumThePig.png';

export const Header = () => {
  return (
    <div className='header'>
      <div>
        <a href='/'>
          <img alt='Ethereum is a pig' width='100px' src={ThePig} />
        </a>
      </div>
      <div>
        Welcome to Ethereum's
        <br />
        <span className='header-title'>Unchained Index</span>
      </div>
      <div>
        <a href='/'>
          <img alt='Ethereum is a pig' width='100px' src={ThePig} />
        </a>
      </div>
    </div>
  );
};
