/* eslint-disable no-multi-str */

//https://github.com/ipfs/go-ipfs/blob/master/docs/examples/go-ipfs-as-a-library/README.md

export var log =
  "\n\
## HackFS Hack Log\n\
\n\
An agonizing recital of what we've been up to for four weeks.\n\
\n\
### Thursday, Aug 6, 2020\n\
\n\
- Finalized submission on HackFS dashboard.\n\
- Created videos and posted them to YouTube.\n\
- Cleaned up Unchained Index website (which turns out to be entirely a presentation with no function).\n\
- Completed 'demo' parts of video. Cleaned up some broken last minute code.\n\
\n\
### Wednesday, Aug 4, 2020\n\
\n\
- Signed up for HackFS judging time slot.\n\
- Practiced with three version of video.\n\
- Complete re-write of horribly inaccurate script for video.\n\
- Complete re-write of presentation, including totally new graphics.\n\
- Rewrote the pinlib code that reads and manages the pin manifest as it had gotten unweildy. Much better now.\n\
- Crunch time. All hacks are good hacks. We can clean up later.\n\
\n\
### Tuesday, Aug 4, 2020\n\
\n\
- Spent the entire day working on the script for the video and the video itself.\n\
- Updated this log of acivity. Completed a few user interviews. Tried to get 'publishManifest' to work by actually posting the IPFS hash of the \
manifest to the chain programatically (until now, this has been a manual process).\
\n\
### Monday, Aug 3, 2020\n\
\n\
- Wasted the entire day arguing with my co-worker over the value of the project we're building. Agreed, in the end, that what we're builing is \
unique, very difficult to explain, worth working on, and that we would do the best we could for the hackathon and start again tomorrow on \
improving the final video.\n\
- The crux of the discussion centered on why the TrueBlocks project was so different than other projects(we agreed that \
the difference is why it's hard to explain).\n\
- We agreed that perhaps the world is not ready for local-first software, and because of that unreadyness \
the world is missing the benefits of client-first software, in particular, all the benefits that we all so love about decentralization including \
privacy, uncensorable data, verifiable data, self-soveriegn data, none of which are possible when there's a remote server in the mix.\
\n\
### Sunday, Aug 2, 2020\n\
\n\
- Took the day off. Helped daughter move into her new apartment. Had dinner (six-feet distant) from her.\
\n\
### Saturday, Aug 1, 2020\n\
\n\
- Took the day off. Went for a long bike ride in a very pretty countryside in New Jersey of all places (who knew).\
\n\
### Friday, Jul 31, 2020\n\
\n\
- Spent the entire day working on a version of the project video trying to make it good enough to show to a panel of Experts (i.e. Juan).\n\
- Got some good feedback from the viewers, but learned after watching myself answer questions that I don't actually listen to people and I answer \
the question I want to answer, which is frequently not the question asked.\
\n\
### Thursday, Jul 30, 2020\n\
\n\
- Worked on preparing a demo video which included transcribing the text of the first draft, editing the content and putting up two versions.\
- Did a couple of user interviews. Very clear from user interviews that the UX is a total mess. Needs to allow the user to 'get right to the point' very quickly.\
\n\
### Wedneday, Jul 29, 2020\n\
\n\
- Worked a lot on publishing the manifest.\n\
- Built and deployed two versions of the Ethereum smart contract (at address 0x50c8f8c71828e594a513cc540176822a57e597c2).\n\
- Did some work on the economics of a user-centered API.\n\
- Wrote code to read from the smart contract and started the code needed to post new records to the smart contract.\
\n\
### Tuesday, Jul 28, 2020\n\
\n\
- Started thinking about how to deploy and use the smart contract.\n\
- Pinned data is being pinned. Pinned data (sharded index chunks) is being consumed during scraping. Kind of working exactly how \
we envisioned so long ago.\n\
- Participated in a user interview for IPFS with Georgia from Consensys. Got some ideas on doing user research. (Warm your user up, get \
them talking about themselves. What do you do in your free time (reveals how devoted they are).\n\
- Interview with Kirk \"There's clearly huge value here.\" (Not sure exactly how to follow through here.) I suggested, and will \
follow up in a few weeks with, another meeting as we move further along. He also saw the many possibilities, but not not really \
suggest we hone in one one. More interested in the many ways the tech can be taken advantage of.\n\
- More user interviews with Simona, Georgia, and Kirk. Simona give great advice about recognizing the many possibilities of the \
code we've built, but purposefully deciding to choose a `Persona` (a typical user) and a `USP` (unique selling proposition).\n\
\n\
### Monday, Jul 27, 2020\n\
\n\
- Designed the format of the publication record.\n\
- Conducted two user interviews. Got good feedback. Users interested in 'monitoring' or 'watching' an address and pushing notifications out to slack as \
an example. (Not sure how to do this decentrally.) Had trouble with second user (from Infura), but he was open to extending use case and allowing me to \
resell his services. I pay him the $250.00, he doesn't care how much I make.\n\
- Participated in user interviews for Mike Ryan and Charlie Mack for Decade. Gave them a bunch of useful feedback for thier project. Signed them up for user testing.\n\
- Rescrape completed on wildmolasses in less than a day. Pinned all to Pinata. That all now seems to work okay. Move on to pushing hash to smart contract.\n\
\n\
### Sunday, Jul 26, 2020\n\
\n\
- Completed rescrape of entire chain producing a full pinned archive to Pinata of 1,000,000 record chunks (there are now half as many). Will now start \
working on querying against blooms only and downloading whichever chunks we need.\n\
\n\
### Saturday, Jul 25, 2020\n\
\n\
- Spent time studying how to build a smart contract in Go (my next task). Thought a lot about the format of the 'publication record.'\n\
- Prepared for full re-scrape of chain on wildmolasses, trying to figure out issue with new scrape. Turns out the 'hash of code' wasn't working. Fixed.\n\
- Got a new TV cause mine was making scratchy noises.\n\
\n\
### Friday, July 24, 2020\n\
\n\
- Implementes listPin and unpinChunk and removed all previously pinned stuff from Pinata in prep for next attempt at full scrape with pinning.\n\
- Added `pinChunk` calls to `blockScrape`. Started a new test that now builds the chunks and then pins them as they are built.\n\
- Added a `removeAllPins` routine to unpin everything as part of testing for new `blockScrape` code.\n\
- Pushed the testing code for pinning out to production code as `pinlib`. Added automated test code. Started process of adding `pinMan` application (`list`, `delete`, `re-pin`, etc.).\n\
- Spent a little bit of time revisiting the smart contract code in anticipation of next week. Once pinning code is working, the only major thing remaining will be \
publishing to a smart contract\n\
\n\
### Thursday, July 23, 2020\n\
\n\
- Fleshed out pinlib a lot by adding functions `pinChunk`, `unpinChunk`, `findChunk`, `writePins`, and `readPins`.\n\
- Fully committed to using Pinata in our code because it has the exact right list of features, but is super light-weight from a usability stand point.\n\
- Spent a long time working with team (wife) to flesh out what video we are going to make. How best to explain our work. Decided against, for the most part \
explaining anything related to TrueBlocks and focus on how (and why) we leverage IPFS.\n\
\n\
### Wednesday, July 22, 2020\n\
\n\
- Spent time meeting people at KERNEL tables. Met William Schwab, a game designer and two other game designers.\n\
- Also, signed up for two other 'office hours' to just discuss our project. Met and made friends with Kris Urbas who is a 'product guy' from Berlin.\n\
- Sent out 10-15 invites to friends to try to get some 'user interviews' for TrueBlocks. Got two to sign up, four on the hook but not yet reeled in.\n\
- Took an interview for an article with Josh Quittner from Decrypt. Was introduced by Rachel Horn who blogged about our project earlier.\n\
\n\
### Tuesday, July 21, 2020\n\
\n\
- Did user interview with Ben D. He was impressed by what we did, but not until he could actually see his own transactions. At first, he said he was \
daunted by the overly-complex user interface. He suggested that we make it much, much simpler. He repeatedly remarked that our UI looked like something for \
a power user.\n\
- Given Ben's comments, we came to understand that what we had done is we had exposed every single feature of the back end code (which is rich) to the \
front end. We did this on purpose, but only so we could 'see everything all in one place.' That's not what a user wants. Duely noted.\n\
\n\
### Monday, July 20, 2020\n\
\n\
- Dug up some old non-working code for pinning on Pinata. Didn't work on Mac. Works on Ubuntu. Going to extend `pinlib` to push data up (and evenutally pull it down).\n\
- Tried to re-enable automated webhooks on server. Worked.\n\
- Reading and listening to videos about IPFS/Pinata/Textile -- still in learning mode. Starting to think complexity of Textile \
is not needed for my project. At least first stab will be simple, post to IPFS - post to Pinata - post to Ethereum smart contract \
on back end and 'listen' to Etheruem - download new blooms - scan bloom - download new index if interested.\n\
- Spent time putting pinata pinning and IPFS pinning into the scraper code. Lot's of backtracking because of difficulty in testing.\n\
- Spent a lot of time over the weekend improving testing and loging of blockScrape. Easier now to modify the code without breaking existing code.\n\
\n\
### Sunday, July 19, 2020\n\
\n\
- Read [this article](https://github.com/ipfs/go-ipfs/blob/master/docs/examples/go-ipfs-as-a-library/README.md) on \
spinning up an IPFS daemon programatically.\n\
- Completed a mental design of the file format for publication to smart contract while showering.\n\
- Re-wrote `blockScrape` by first improving loging and testing.\n\
\n\
### Saturday, July 18, 2020\n\
\n\
- Spent the entire day ripping out yesterday's changes as they introduced regressions. Built out much needed debugging/testing code for \
the block scraper which has always been under-tested.\n\
- Re-started a complete new scrape of the index on `wildmolasses.`  Found that `linux` box produces an incorrect index, but that this is a bug in \
Parity, not our code. We report exactly what Parity says. If Parity `--tracing` or `--archive` nodes are upgraded after having cached traces, it does \
not go back and re-cache those old traces. We knew this, but thought it had been resolved. I guess not.\n\
\n\
### Friday, July 17, 2020\n\
\n\
- Started re-writing `blockScrape` in preparation for adding 'ipfs publish' code.\n\
- Started sharing code for TrueBlocks Explorer.\n\
- Opened our linux local host to allow for incoming connections (server version).\n\
\n\
### Thursday, July 16, 2020\n\
\n\
- Investegated (again) using a simple pinning service such as Pinata. The more I learn, the less I think I need complicated\n\
solutions such as FileCoin and Textile. Not sure what these things bring given my very simple needs.\n\
- Used [this tutorial](https://medium.com/hackernoon/a-step-by-step-guide-to-testing-and-deploying-ethereum-smart-contracts-in-go-9fc34b178d78) to \
try to build a tool to interact with an Ethereum smart contract.\n\
- Uninstalled and re-installed 'go' on my machine because I need a way to post transactions to the chain programattically \
which I think I can do with geth and go.\n\
- Compiled and ran Solidity contract. Came up with idea of 'type' in the publishing events -- types: 'file' and 'index' where \
'file' happens once at start of program (gas savings) and 'index' happens periodically. Also worked on a spreadsheet that will \
help me explain ongoing cost of the system and make it clear how little it costs.\n\
\n\
### Wedensday, July 15, 2020\n\
\n\
- Spent a lot of time futzing around with Solidity programming. Tools suck!\n\
- GitCoin Kernel event.\n\
- Call with Racheal Horne about my project.\n\
- Unproductive day.\n\
\n\
### Tuesday, July 14, 2020\n\
\n\
- Wrote long-winded description of the project I'm trying to work on for the HackFS hackathon page.\n\
- Debugged fix to index creation code in blockScrape which had a bug related to the value of K for the bloom filters.\n\
- Attended unstoppable domains session on Slack\n\
- Cleaned up blockScrape code to make better sense of its operation.\n\
- Started the code to push the indexed chunks and index blooms to IPFS (the first part of the publishing problem).\
- Took my car for service\
\n\
### Monday, July 13, 2020\n\
\n\
- Spent a lot of time working on a bug in the index scraper related to how bloom filters are created. Found that we \
  were lighting ten bits but only using five unique bit positions. Fix this bug. Had to re-run the index scraper, but this\
  made it clear to us how to put the index on a remote machine.\
- Investigated [this issue](https://stackoverflow.com/questions/54206621/solidity-source-files-requires-different-compiler-version). Installed [this](https://github.com/crytic/solc-select).\n\
- Revisited Austin's attestor smart contract. Going to work this week on publishing hash announcements to IPFS.\n\
- Building something that minimizes cost, minimizes size of data on end user's machine, maximizes availability, maximizes information content.\n\
\n\
### Sunday, July 12, 2020\n\
\n\
- Spent time working on go version of `acctScrape` with Dave Appleton. Looking to speed up initial chain scan by 10-15 times.\n\
- Spent a lot of time refactoring old bloom code (removing most of it) and promoting working bloom code to main libraries.\n\
- Went on a nice, long bike ride with Meriam to Mill Grove, John J. Audobon's first home in the US.\n\
\n\
### Saturday, July 11, 2020\n\
\n\
- Redownloaded and began extracting needed code from Austin's scaffold-eth. Decided against trying to stay inside of his structure, and chose \
instead to use his code as a starting point and bring it wholesale into my code, giving me greater freedom.\n\
- Ordered milk and coffee from Wawa\n\
- Decided on the design of what I'm going to build:\n\
  - Will be building a smart contract that allows publishing (and attesting to) IPFS hashes of both the bloom filters and the index \
  chunks each time a chunk becomes available (around every four hours).\n\
  - Users will listen to the smart contract events and, when a new chunk appears, will download the bloom (which is very small) and \
  check it against any addresses they are interested in.\n\
  - If the bloom hits, only then will they download and scan the chunk.\n\
  - Users will download and pin all the bloom files as they appear.\
    - They may start later than the first block, but once they start they will pin every bloom.\
    - We need a chart of the size of the blooms to help them understand where they want to start.\
  - Pinning blooms will allow them to add new addresses easily.\
  - This system places no ongoing burden on my website, in fact, I may not even have to build a website at all other than to deliver the \
    software and explain how it works.\
- Users may choose to run their own indexer (we would need to build two versions of the index -- events only and traces). Smart contract may \
need to keep a counter. From my perspective this does not add to the cost since I'm only attesting once per chunk, so my costs are fixed.\n\
- Probably need 'snap-to-block' to ensure easier corrective measures.\n\
- Ready, steady, go on the real coding.\n\
\n\
### Friday, July 10, 2020\n\
\n\
- Completed tutorials from Austin\n\
  - [Connecting ETH to IPFS](https://medium.com/@austin_48503/tl-dr-scaffold-eth-ipfs-20fa35b11c35)\n\
  - [Decentralized Deployment](https://medium.com/@austin_48503/decentralized-deployment-7d975c9d5016)\n\
- Joined Austin Griffith's webinar on scaffold.eth\n\
- Went through a tutorial for Truffle in case I need to write a smart contract. Got it to work, but just barely.\n\
- Took out the trash\n\
\n\
### Thursday, July 9, 2020\n\
\n\
- Added `ReactMarkdown` pages to the app (about, how to, about, etc.)\n\
- Joined Textile office hours. Asked question about the Textile stack and application data.\n\
- Joined Fleek Workshop. Learned about fleek's javascript SDK which looks good. Will investigate.\n\
- Read more about webhooks (might use it to report to server to pull new index chunk)\n\
\n\
### Wednesday, July 8, 2020\n\
\n\
- Some styling of the website\n\
- Enabled webhook for [unchainedindex.io](http://unchainedindex.io) website\n\
- Added a few more HOWTOs:\n\
  - [Installing Node](HOWTO/9.0%20Installing%20Node.md)\n\
\n\
### Tuesday, July 7, 2020\n\
\n\
- Added right and left menus to React app.\n\
- Read this article [Automatically Deploy from GitHub using Webhook](https://medium.com/better-programming/how-to-automatically-deploy-from-github-to-server-using-webhook-79f837dcc4f4)\n\
  - Tried to enable auto deploy of the website. Couldn't get it to work\n\
- Wrote a bunch of HOWTO markdown to make starting over easier [here](./HOWTO)\n\
  - [Creating Digital Ocean Droplet](HOWTO/1.0%20Creating%20Digital%20Ocean%20Droplet.md)\n\
  - [Installing Fish Shell](HOWTO/2.0%20Installing%20Fish%20Shell.md)\n\
  - [Installing Go](HOWTO/3.0%20Installing%20Go.md)\n\
  - [Installing C++ Build Environment copy](HOWTO/4.0%20Installing%20C++%20Build%20Environment.md)\n\
  - [Installing Nginx](HOWTO/5.0%20Installing%20Nginx.md)\n\
  - [Setting up Virtual Servers](HOWTO/6.0%20Setting%20up%20Virtual%20Servers.md)\n\
  - [Setting up a Second Virtual Server](HOWTO/7.0%20Setting%20up%20a%20Second%20Virtual%20Server.md)\n\
  - [Installing Web Hooks to Deploy Website](HOWTO/8.0%20Installing%20Web%20Hooks%20to%20Deploy%20Website.md)\n\
  - [Enabling HTTPS on Nginx](HOWTO/Enabling%20HTTPS.md)\n\
\n\
### Monday, July 6, 2020\n\
\n\
- Setup a new droplet on Digital Ocean for unchainedindex.io\n\
- Followed [Tutorial: Setting up an IPFS peer, part I](https://medium.com/textileio/tutorial-setting-up-an-ipfs-peer-part-i-de48239d82e0). Couldn't get it to work.\n\
- Tried out Fleek for auto deploy option.\n\
  - Removed because didn't understand the benefit of Fleek over straight installing it myself\n\
  - Question: If I want to use Textile, will I be able to on the `onFleek.co` servers?\n\
- Established [first React version of website](http://unchainedindex.io) [[repo](https://github.com/tjayrush/unchainedindex.io)].\n\
";
