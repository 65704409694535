import React from 'react';

import './App.css';

import { Header } from 'Header';
import { Body } from 'Body';
import { Footer } from 'Footer';

function App() {
  return (
    <div className='page'>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
