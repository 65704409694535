/* eslint-disable no-multi-str */

export var thoughts =
  "\n\
## Thoughts / Next Steps\n\
\n\
- TrueBlocks is trying to build something that minimizes the cost to the provider, maximizes data availability to the end user, and benefits \
    the entire community as a by-product.\n\
- If an application shards its data so that the users themselves pin their own portion, and that pinning is on-by-default, the entire system (all users and providers) benefits.\n\n\
- If users store not only their portion, but a little bit of other people's portion, there is built in redundancy.\n\n\
- Many people talk about IPFS from the perspective of a front end developer. They think of IPFS (and Filecoin) as a service provider, not as a way \
    to distribute data to end users.\n\n\
- Is pushing an attestation (git commit) enough to prove the data to the end user? Does the end user even care?\n\
- Should we post a small deposit for each attestation that is rewarded to users who identify problems? Who adjudicates?\n\n\
- What if we published only event-related appearances like *theGraph*? Could we sell the full index of all appearances anywhere? Could we \
    duplicate (and enhance) the function of *theGraph* on local machines?\n\n\
- We could do private notifications on the end user's machines.\n\
- We should estimate the ongoing cost of running the system for a month? For a year?\n\n\
- We need a graphic showing the size of blooms (tiny) vs. the size of index data (big) vs. the size of actual Ethereum data (gigantic).\n\n\
- Textile is overlay on top of IPFS / Filecoin. Fleek seems to be an overlay on top of Textile / IPFS / Filecoin.\n\n\
- We built a once-audited, radically-shared, reproducable, immutible index of every appearance of every address anywhere on the \
    chain. Once other people understand this, and confirm that it's correct, no-one every has to review this history again. All they need \
    is the hash to the manifest.\n\
- The community will have to maintain vigelance at the front of the chain.\n\
\n\n\
";
