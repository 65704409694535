import React from 'react';

import { Menu, MenuItem } from 'components';
import { leftMenu } from './Menus';

export const BodyLeft = ({ onClick, curMenu }) => {
  return (
    <div className='body-left'>
      <Menu data={leftMenu} onClickItem={onClick}>
        {({ items }) => (
          <ul style={{ padding: '0px' }}>
            {items.map(({ key, ...props }) => (
              <MenuItem key={key} defMenu={curMenu} {...props} />
            ))}
          </ul>
        )}
      </Menu>
    </div>
  );
};
