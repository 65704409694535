/* eslint-disable no-multi-str */

export var what =
  "\
## What the Unchained Index is:\n\
\n\
The Unchained Index explores how we might **rethink** web 2.0 APIs.\n\
\n\
- Can we retain certain features of the Web 2.0 stack (high availability, ease of use, \
speed), without sacrificing the amazing charactistics of Web 3.0 (immutability, uncensorability, \
deterministic reproducability)?\n\n\
- Can we deliver a fully private experience to the end user?\n\
\n\
We used the TrueBlocks Account Explorer as an example of how this work can be integrated into an end user application.\n\
\n\
We've made an attempt of explaining our design through the use of via negativa (saying what it's not).";
