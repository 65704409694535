import React, { Link } from 'react';

import ReactPlayer from 'react-player';

export const HomePage = () => {
  return (
    <div>
      <br />
      <center>
        <big>
          <i>An unstoppable, uncapturable index of everything that ever happened on Etheruem mainnet</i>
        </big>
      </center>
      <h2>Note:</h2>
      <div>
        This website is in addition to our submission which is a{' '}
        <a target='_blank' href='https://github.com/TrueBlocks/trueblocks-explorer/blob/master/README.md'>
          desktop aplication
        </a>{' '}
        and{' '}
        <a target='_blank' href='https://github.com/TrueBlocks/trueblocks-core'>
          a series of tools
        </a>{' '}
        using what we call a Web 3.0 native API.
        <p></p>
        The project is best explained by the video:
      </div>
      <br />
      <center>
        <ReactPlayer
          style={{ border: '2px solid black' }}
          width='832px'
          height='546px'
          url='https://www.youtube.com/watch?v=gqqgPfCvCAQ'
        />
      </center>
      <h2>The Rest of This Site</h2>
      <div>
        The rest of this site collects together some thoughts about what we built, why we built it, file formats for the
        data produced, and a hack log describing what we did over the last month
      </div>
    </div>
  );
};
