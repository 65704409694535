import React, { useState } from 'react';

import { BodyLeft } from './BodyLeft';
import { BodyMiddle } from './BodyMiddle';

import './Body.css';

export const Body = () => {
  const [curMenu, setCurMenu] = useState({ key: 'home' });

  const onClick = (action) => {
    const { key, label, ...props } = action;
    console.log(key, ' ', label); // user defined prop
    setCurMenu({ key: key, label: label, ...props });
    console.log(props.openNodes);
  };

  return (
    <div className='body'>
      <BodyLeft onClick={onClick} curMenu={curMenu} />
      <BodyMiddle onClick={onClick} curMenu={curMenu} />
    </div>
  );
};
