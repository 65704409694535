// prettier-ignore
export const leftMenu = [
         { key: 'home', label: 'Home' },
         { key: 'what', label: 'What this is' },
         { key: 'negativa', label: 'What this is not' },
         { key: 'formats', label: 'File Formats' },
         { key: 'source', label: 'Source Code' },
         { key: 'log', label: 'Hack Log' },
         { key: 'thoughts', label: 'Thoughts' },
         { key: 'mantras', label: 'Mantras' },
         { key: 'tallies', label: 'Tally' },
         { key: 'contact', label: 'Contact Us' },
         { key: 'about', label: 'About Us' },
       ];
