import React from 'react';
import ReactMarkdown from 'react-markdown';

import { Formats, HomePage, what, log, negativa, Source, mantras, tallies, contact, AboutBox, thoughts } from 'pages';

import { PDFDocument } from 'components/PDFDocument';

export const BodyMiddle = ({ onClick, curMenu }) => {
  const page = curMenu.key && curMenu.key.replace(curMenu.parent + '/', '');
  return (
    <div className='body-middle'>
      <div className='body-inner'>{getInnerPage(curMenu.parent, page, curMenu.label)}</div>
    </div>
  );
};

const getInnerPage = (parent, page, label) => {
  let code;
  switch (page) {
    case 'home':
      code = <HomePage />;
      break;
    case 'formats':
      code = <Formats />;
      break;
    case 'about':
      code = <AboutBox />;
      break;
    case 'source':
      code = <Source />;
      break;
    case 'contact':
      code = <ReactMarkdown source={contact} />;
      break;
    case 'log':
      code = <ReactMarkdown source={log} />;
      break;
    case 'negativa':
      code = <ReactMarkdown source={negativa} />;
      break;
    case 'mantras':
      code = <ReactMarkdown source={mantras} />;
      break;
    case 'tallies':
      code = <ReactMarkdown source={tallies} />;
      break;
    case 'thoughts':
      code = <ReactMarkdown source={thoughts} />;
      break;
    case 'what':
      code = <ReactMarkdown source={what} />;
      break;
    default:
      code = (
        <div>
          <div>Parent:</div>
          <div>{parent}</div>
          <div>Page:</div>
          <div>{page}</div>
          <div>Label:</div>
          <div>{label}</div>
        </div>
      );
      break;
  }

  return <div>{code}</div>;
};
