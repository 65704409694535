/* eslint-disable no-multi-str */
import React from 'react';

export const Formats = () => {
  return (
    <div>
      <h2>File Formats</h2>
      <div>
        In order for anyone to use the index, they need to know the format of the data. This page lists IPFS hashes
        pointing to the latest pin manifest and the file formats needed to read the pin manifest.
      </div>
      <h4>Latest Manifest</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://gateway.pinata.cloud/ipfs/QmcvjroTiE95LWeiP8HHq1YA3ysRchLuVx8HLQui8WcSBV'>
        Qmart6XP9XjL43p72PGR93QKytbK8jWWcMguhFgxATTya2
      </a>
      <h4>Index File Format</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://gateway.pinata.cloud/ipfs/Qmart6XP9XjL43p72PGR93QKytbK8jWWcMguhFgxATTya2'>
        Qmart6XP9XjL43p72PGR93QKytbK8jWWcMguhFgxATTya2
      </a>
      <h4>Bloom Filter File Format</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://gateway.pinata.cloud/ipfs/QmNhPk39DUFoEdhUmtGARqiFECUHeghyeryxZM9kyRxzHD'>
        QmNhPk39DUFoEdhUmtGARqiFECUHeghyeryxZM9kyRxzHD
      </a>
    </div>
  );
};
