/* eslint-disable no-multi-str */

export var tallies =
  '\
## Centralization Tally\n\n\
Being rather anal retentive about keeping track of where our code has centralization risk, here we list those portions of our code that are either fully or partly centralized:\n\n\
- Pinata and the IPFS gateway are fully centralized web 2.0 APIs\n\n\
- Unless user run her own node, transactional details are retrieved remotely\n\n\
- ABI files are collected from Etherscan (the community will have to decentralize this)\n\n\
- Price feed from poloniex (this can be decentralized for newer blocks)\n\n\
';
