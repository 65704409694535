import React from 'react';

import './Footer.css';

export const Footer = () => {
  return (
    <div className='footer'>
      <div>A</div>
      <div className='footer-center'>
        An unstoppable, uncapturable index of everything that ever happened on Etheruem mainnet
      </div>
      <div>c</div>
    </div>
  );
};
