/* eslint-disable no-multi-str */
import React from 'react';

export const Source = () => {
  return (
    <div>
      <h2>Source Code</h2>
      <div>This page points to the source code used in the project:</div>
      <h4>TrueBlocks Core (back end)</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://github.com/TrueBlocks/trueblocks-core/tree/develop'>
        http://github.com/TrueBlocks/trueblocks-core/
      </a>
      <h4>TrueBlocks Account Explorer (front end)</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://github.com/TrueBlocks/trueblocks-explorer/tree/develop'>
        http://github.com/TrueBlocks/trueblocks-explorer/
      </a>
      <h4>Unchained Index Smart Contract</h4>
      <a
        style={{ marginLeft: '20px' }}
        target='_blank'
        href='https://github.com/TrueBlocks/trueblocks-core/tree/develop/src/go-apps/unchained'>
        https://github.com/TrueBlocks/trueblocks-core/.../go-apps/unchained
      </a>
    </div>
  );
};
