/* eslint-disable no-multi-str */

export var contact =
  '\
## Contact Us\n\n\
- Email:\n\n\
  - [support@trueblocks.io](mailto://support@trueblocks.io)\n\n\
- GitHub\n\n\
  - [Unchained Index](https://github.com/tjayrush/unchainedindex.io)\n\n\
  - [TrueBlocks Core](https://github.com/TrueBlocks/trueblocks-core)\n\n\
  - [TrueBlocks Explorer](https://github.com/TrueBlocks/trueblocks-explorer)\n\n\
- Physical:\n\n\
  - 1010 N Hancock St, Philadelpia, PA USA 19123\
';
