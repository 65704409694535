/* eslint-disable no-multi-str */

export var mantras =
  '\
## Mantras for Decentralized Data\n\n\
We keep an ongoing list of mantras that, if repeated endlessly, will eventually sink in:\n\n\
- All the data, for all the people, all the time, but no data if the user doesn’t want it\n\n\
- Use only consented-to data…\n\n\
  - If you can’t do that, use only data derived directly from consented-to data\n\n\
  - If you can’t do that, find a way to force the data provider to prove their data\n\n\
- The paradigm has shifted, embrace it - the server is now called **localhost**\n\n\
- Break yourself out of the browser. There’s a big wide desktop out there\n\n\
- It’s easier to scale up than to scale down - **decentralize first**\n\n\
- Most users care only about themselves and maybe a few smart contracts\n\n\
- Some users, but not all, care about everything\n\n\
- Everyone needs to do accounting and pay taxes\n\n\
- The node can, and should be, be improved\n\n\
';
